import React, { useState, useEffect } from 'react';
import {Link} from 'gatsby';
import styled from '@emotion/styled';
import {FlexContainer, orange, dark, darkgrey, navmenu, Toggler, menusidebar, menuicons, menulogo, headericon, navcloser, menucontact, menulogoinline, navlink, background2, mq, white} from '../styles/globalstyles';
import { motion, AnimatePresence } from "framer-motion"
import Image from './image';
import {css} from '@emotion/react';
import {menuenterleft} from './animations';
import {FaFacebook, FaXingSquare, FaYoutubeSquare, FaLinkedin, FaRegEnvelope} from 'react-icons/fa'

const variants = {
  initial: {opacity: 0, y:-80, transition: { duration: 0.4, opacity: {delay: 0.5}}},
  animate: {opacity: 1, y: 0, transition: {ease: "easeOut", delay: 0.4 }}
}
const menuToggler1 ={
  open: { x:0, },
  closed: { x: -5 }
}
const menuToggler2 ={
  open: { x:0},
  closed: { x:5}
}
const menuToggler3 = {
  open: {x:0 },
  closed: {x: -5 }
}
const NavContainer = styled.div({
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    top: 0,
    left: 0,
    background: "transparent",
 })
const navlist = {
  initial: {
    transition: { staggerChildren: 0.05, delayChildren: 0.2 }
  },
  visible: {
    transition: { staggerChildren: 0.05, delayChildren: 0.15 }
  },
  hidden: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 }
  }
};
const navitem = {
  initial: {
    y: 40,
    opacity: 0,
    transition: {
      ease: "easeOut"
    }
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: "easeOut"
    }
  },
  hidden: {
    y: 40,
    opacity: 0,
    transition: {
      ease: "easeOut"
    }
  }
};
const navborderitems = {
  initial: {opacity: 0, transition: {delay: 0, duration: 0.1}},
  animate: {opacity: 1, transition: {delay: 0.2, duration: 0.6}},
  exit: {opacity: 0, transition: {delay: 0, duration: 0.6}},
}

const navbackground = {
  initial: {backgroundColor: "rgba(0,0,0,0)", transition: {delay: 0, duration: 0.4}},
  animate: {backgroundColor: "rgba(0,0,0,0.3)", transition: {delay: 0, duration: 0.6}},
  exit: {backgroundColor: "rgba(0,0,0,0)", transition: {delay: 0, duration: 0.6}}
}
const linkstyle = mq({
  color: "inherit",
  textDecoration: "none",
  fontSize: ["1.2rem", "1.2rem","1.8rem"],
  textTransform: "uppercase",
  lineHeight: ["1.2rem", "1.2rem","1.8rem"],
  transition: "all 0.4s ease-out",
  fontWeight: 700,
  letterSpacing: "-0.5px",
  borderBottom: "2px solid transparent",
  fontFamily: "Montserrat",
  [":hover"]: {color: orange}
})
const sublinkstyle = mq({
  color: "inherit",
  marginTop:["0.1rem", "0.2rem", "0.3rem"],
  textDecoration: "none",
  fontSize: ["0.7rem", "0.7rem","0.9rem"],
  lineHeight: "0.9rem",
  transition: "all 0.4s ease-out",
  fontWeight: 500,
  letterSpacing: "-0.5px",
  borderBottom: "2px solid transparent",
  fontFamily: "Montserrat",
  textTransform: "none",
})
const liststyle = mq({
width: "100%",
flex: ["0 1 400px","0 1 500px", "0 1 600px"],
listStyle: "none",
overflowY: ["scroll","hidden", "hidden"],
display: "flex",
flexDirection: "column",
alignItems: "flex-start",
justifyContent: "space-between",
padding: 0,
gap: "0.9rem"
})
const listitem = mq({
width: ["90%", "90%", "90%"],
float: ["none", "none", "left"],
margin: ["0.0.3rem 0", "0.0.3rem 0", "0.3rem 0"],
padding: 0,
})

const Navigation = ({showMenu}) => {
    const [collapse, setCollapse] = useState(false)
    const [show, setShow] = useState(true)
    const handleScroll = (e) => (window.scrollY > 150 ? setShow(false) : setShow(true))
    useEffect(() => {
    window.addEventListener("scroll", handleScroll, false);
    return () => window.removeEventListener("scroll", handleScroll, false);
    },[]);

    const menuHandler =() => {
      collapse ? setTimeout(() => { 
      const body = document.body;
      const html = document.documentElement
      const scrollY = body.style.top;
      body.style.overflowY = 'visible';
      body.style.paddingRight = '0px';
      html.style.overflowY = 'visible';
      setCollapse(!collapse)
      
      }, 600) : document.documentElement.style.getPropertyValue('--scroll-y');
      const body = document.body;
      const html = document.documentElement
      body.style.overflowY = 'hidden';
      body.style.paddingRight = '10px';
      html.style.overflowY = 'hidden';
    setCollapse(!collapse)
    }

    return (
      <>
        {showMenu && 
        <>
        <NavContainer css={css`z-index: 9;`}> 
            <AnimatePresence exitBeforeEnter> 
            {collapse && [
            <motion.div key="navbackground" variants={navbackground} initial="initial" animate="animate" exit="exit"  css={css`width: 100vw; height: 100vh; overflow: hidden; z-index: 9;background: ${white};`}>
               <motion.div key="76365"  onClick={() => menuHandler()} css={navcloser} />
                <motion.div key="345" css={navmenu} 
                  initial="initial"
                  animate="visible"
                  exit="hidden"
                  variants={menuenterleft}
                >
                  <div css={css`width: 100%; height: 100%; padding-left: 0 !important; overflow: hidden !important;`}> 
                    <FlexContainer direction="column" align="flex-start" justify="space-between" css={css`background-color: ${white} !important; height: 100%; position: relative;  width: 100%; padding-top: 0.8em; padding-bottom: 0.8em; overflow-y: hidden !important; z-index: 10;`}>  
                        
                        <motion.div key="40976" variants={navborderitems} initial="initial" animate="animate" exit="exit"  css={css`height: auto; width: 100%; border-bottom: 1px solid ${dark}; padding-bottom: 0.6rem; margin-bottom: 1.2rem;`}>
                            <div css={menulogoinline}>
                              <Image image="logofull" />
                            </div>
                        </motion.div>
              
                        <motion.div css={liststyle} variants={navlist} initial="initial" animate="visible" exit="hidden"> 
                          <motion.li variants={navitem} css={listitem} key="23244">
                            <Link
                              onClick={() => menuHandler()}
                              to="/"
                              css={linkstyle}
                              activeStyle={{color: orange, borderBottom: "2px solid " + orange}}
                            >
                              <div css={linkstyle}>
                                Start
                              </div>
                              <div css={sublinkstyle}>
                                Machen Sie Ihr Unternehmen leistungsfähiger
                              </div>
                            </Link>
                          </motion.li>
                          <motion.li variants={navitem} css={listitem} key="45478">
                            <Link
                              onClick={() => menuHandler()}
                              to="/beratung"
                              css={linkstyle}
                              activeStyle={{color: orange, borderBottom: "2px solid " + orange}}
                            >
                              <div css={linkstyle}>
                                Service
                              </div>
                              <div css={sublinkstyle}>
                                Klares Zielbild für Ihr Unternehmen
                              </div>
                            </Link>
                          </motion.li>
                          <motion.li variants={navitem} css={listitem} key="23545">
                            <Link
                              onClick={() => menuHandler()}
                              to="/akademie/"
                              css={linkstyle}
                              activeStyle={{color: orange, borderBottom: "2px solid " + orange}}
                              partiallyActive={true}
                            >
                              <div css={linkstyle}>
                                Akademie
                              </div>
                              <div css={sublinkstyle}>
                                Wir vermitteln unser Wissen
                              </div>
                            </Link>
                          </motion.li>
                          <motion.li variants={navitem} css={listitem} key="29563">
                            <Link
                              onClick={() => menuHandler()}
                              to="/kooperationen/"
                              css={linkstyle}
                              activeStyle={{color: orange, borderBottom: "2px solid " + orange}}
                              partiallyActive={true}
                            >
                              <div css={linkstyle}>
                                Kooperationen
                              </div>
                              <div css={sublinkstyle}>
                                Lernen Sie unser Partnernetzwerk kennnen
                              </div>
                            </Link>
                          </motion.li>
                          <motion.li variants={navitem} css={listitem} key="48673">
                            <Link
                              onClick={() => menuHandler()}
                              to="/wissenswertes/"
                              css={linkstyle}
                              activeStyle={{color: orange, borderBottom: "2px solid " + orange}}
                              partiallyActive={true}
                            >
                              <div css={linkstyle}>
                                Wissenswertes
                              </div>
                              <div css={sublinkstyle}>
                                Erfahren Sie, was uns antreibt
                              </div>
                            </Link>
                          </motion.li>
                          <motion.li variants={navitem} css={listitem} key="34656">
                            <Link
                              onClick={() => menuHandler()}
                              to="/team/"
                              css={linkstyle}
                              activeStyle={{color: orange, borderBottom: "2px solid " + orange}}
                              partiallyActive={true}
                            >
                              <div css={linkstyle}>
                                Team
                              </div>
                              <div css={sublinkstyle}>
                                Lernen Sie uns kennen
                              </div>
                            </Link>
                          </motion.li>
                          <motion.li variants={navitem} css={listitem} key="46285">
                            <Link
                              onClick={() => menuHandler()}
                              to="/kontakt"
                              css={linkstyle}
                              activeStyle={{color: orange, borderBottom: "2px solid " + orange}}
                              partiallyActive={true}
                            >
                              <div css={linkstyle}>
                                Kontakt
                              </div>
                              <div css={sublinkstyle}>
                                Wie Sie uns erreichen
                              </div>
                            </Link>
                            </motion.li>
                        </motion.div>
                    
                        <motion.div variants={navborderitems} initial="initial" animate="animate" exit="exit"  key="48768" css={css`height: auto; width: 100%; border-top: 1px solid ${dark}; padding-top: 0.6rem; margin-top: 1.2rem;`}>
                          <div css={css`width: 100%; height: 100%; display: flex; flex-direction: row; justify-content: flex-start; gap: 1.2rem;`}>
                            <Link css={navlink} onClick={() => menuHandler()} to="/impressum/" activeStyle={{color: orange, borderBottom: "1px solid " + orange}}> Impressum</Link>
                            <Link css={navlink} onClick={() => menuHandler()} to="/datenschutzerklaerung/" activeStyle={{color: orange, borderBottom: "1px solid " + orange}}> Datenschutz</Link>
                            <Link css={navlink} onClick={() => menuHandler()} to="/agb/" activeStyle={{color: orange, borderBottom: "1px solid " + orange}} > AGB</Link>
                          </div>
                        </motion.div>
                      </FlexContainer> 
                    </div>
              </motion.div>
          </motion.div>
            ]}
   
        </AnimatePresence>
      </NavContainer>
        <div css={menusidebar}>
          <div css={menulogo}><Link css={css`height: 2.6em; width: auto;`} to="/"><Image image="logofull"  /> <span css={{opacity: 0, position: "absolute", fontSize: "0.1em"}}>Proceed</span> </Link> </div>
          <div css={menucontact}><Link css={css`font-size: 1.2em; text-align: center; transition: all 0.2s ease-in; margin-bottom: 0.3rem; &:hover {color: ${orange};}`} to="/kontakt"><FaRegEnvelope  /> <span css={{opacity: 0, position: "absolute", fontSize: "0.1em"}}>Kontakt</span> </Link> 
          <h6 css={css`font-size: 0.5em; color: ${orange} !important;`}>kontakt</h6>
          </div>
            <Toggler onClick={() => menuHandler()} >
              <h6 css={css`font-size: 0.66em; color: ${orange}; margin-bottom: 0.4em;`}>MENÜ</h6>
                <motion.div
                key="123"
                variants={menuToggler1}
                initial="open"
                animate={collapse ? "closed" : "open"}
                css={css`width: 100%; height: 4px; background: ${dark}; position: relative; margin-top: 0; border-radius: 12px; box-shadow: 4px 4px -5px 4px;`}
                ></motion.div>
                <motion.div
                key="125"
                variants={menuToggler2}
                initial="open"
                animate={collapse ? "closed" : "open"}
                css={css`width: 100%; height: 4px; background: ${dark}; position: relative; margin-top: 4.5px; border-radius: 12px; box-shadow: 4px 4px -5px 4px;`}
                ></motion.div>
                <motion.div
                key="128"
                variants={menuToggler3}
                initial="open"
                animate={collapse ? "closed" : "open"}
                css={css`width: 100%; height: 4px; background: ${dark}; position: relative; margin-top: 4.5px; border-radius: 12px; box-shadow: 4px 4px -5px 4px;`}
                ></motion.div>
            </Toggler>
            <div css={menuicons}> 
              <a rel="noreferrer" href="https://www.facebook.com/team.proceed/" target="_blank" css={css`font-size: 1.0em; color: ${darkgrey}; transition: all 0.5s ease-out; cursor: pointer; &:hover {color: ${orange};}`}> <span css={{opacity: 0, position: "absolute", fontSize: "0.1em"}}>Facebook</span> <FaFacebook /> </a>
              <a rel="noreferrer" href="https://www.linkedin.com/company/proceed-gmbh-co-kg/" target="_blank" css={css`font-size: 1.0em; color: ${darkgrey}; transition: all 0.5s ease-out; cursor: pointer; &:hover {color: ${orange};}`}><span css={{opacity: 0, position: "absolute", fontSize: "0.1em"}}>LinkedIn</span> <FaLinkedin /></a>
              <a rel="noreferrer" href="https://www.xing.com/profile/Michael_Kirsch35" target="_blank" css={css`font-size: 1.0em; color: ${darkgrey}; transition: all 0.5s ease-out; cursor: pointer; &:hover {color: ${orange};}`}><span css={{opacity: 0, position: "absolute", fontSize: "0.1em"}}>Xing</span> <FaXingSquare /></a>
              <a rel="noreferrer" href="https://www.youtube.com" target="_blank" css={css`display: none; font-size: 1.0em; color: ${darkgrey}; transition: all 0.5s ease-out; cursor: pointer; &:hover {color: ${orange};}`}> <FaYoutubeSquare /></a>
            </div>
      </div>
      </>
    }

      <motion.div
        css={headericon}
        className="boxShadow"
        variants={variants}
        initial="initial"
        animate={show ? "animate" : "initial"}
        key="12575"
        >
          {showMenu ? 
          <Link 
          to="/"
          > 
            <Image image="logofull" />
          </Link>
          :
          <a 
          href="https://www.proceed.gmbh"
          target='__blank'
          > 
            <Image image="logofull" />
          </a>
          
        
        }
        </motion.div>
      </>
    )




}
export default Navigation